import { HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';

import { filter, first, switchMap } from 'rxjs';

import { Store } from '@ngrx/store';

import { selectActiveContext } from '@ciphr/core/auth-context/state';

export const authContextInterceptor = (req: HttpRequest<unknown>, next: HttpHandlerFn) => {
  const store = inject(Store);

  return store.select(selectActiveContext).pipe(
    filter(Boolean),
    first(),
    switchMap((context) => {
      let contextualReq = req.clone();

      if (context) {
        contextualReq = contextualReq.clone({ setHeaders: { 'AppContext-CompanyCode': context.companyCode } });

        if (context.adminUserLoginName) {
          contextualReq = contextualReq.clone({ setHeaders: { 'AppContext-AdminUserLoginName': context.adminUserLoginName } });
        }

        if (context.employeeCode) {
          contextualReq = contextualReq.clone({ setHeaders: { 'AppContext-EmployeeCode': context.employeeCode } });
        }
      }

      return next(contextualReq);
    }),
  );
};
