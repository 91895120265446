import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { inject, Injectable } from '@angular/core';

import { catchError, map, mergeMap, switchMap, throwError } from 'rxjs';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { APP_NAVIGATION_DATA_ADAPTER } from './app-navigation-data-adapter.token';
import { appNavigationDataAdapterActions } from './actions/app-navigation-data-adapter.actions';
import { appNavigationFeatureActions } from './actions/app-navigation-feature.actions';

@Injectable()
export class AppNavigationEffects {
  private actions$ = inject(Actions);
  private breakpointObserver = inject(BreakpointObserver);
  private dataAdapter = inject(APP_NAVIGATION_DATA_ADAPTER);
  private store = inject(Store);

  loadNavigationMenu$ = createEffect(() =>
    this.actions$.pipe(
      ofType(appNavigationFeatureActions.loadNavigationMenu),
      mergeMap(() => this.breakpointObserver.observe(Breakpoints.Handset)),
      switchMap(({ matches }) =>
        this.dataAdapter
          .fetchNavigation(matches)
          .pipe(map((navigationMenu) => appNavigationDataAdapterActions.navigationMenuLoadedSuccessfully({ navigationMenu }))),
      ),
    ),
  );

  searchNavigationLinks$ = createEffect(() =>
    this.actions$.pipe(
      ofType(appNavigationFeatureActions.searchNavigationLinks),
      switchMap(({ searchValue }) => {
        return this.dataAdapter.fetchNavigation(this.breakpointObserver.isMatched(Breakpoints.Handset), searchValue).pipe(
          map((results) => appNavigationDataAdapterActions.navigationLinksSearchedSuccessfully({ results })),
          catchError((httpErrorResponse) => {
            this.store.dispatch(appNavigationDataAdapterActions.navigationLinksSearchingFailed());
            return throwError(() => httpErrorResponse);
          }),
        );
      }),
    ),
  );
}
