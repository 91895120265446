import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { AuthContext } from '@ciphr/core/auth-context/models';

export const authContextDataAdapterActions = createActionGroup({
  source: 'Auth Context Data Adapter',
  events: {
    'Contexts List Loaded Successfully': props<{ contextsList: AuthContext[] }>(),
    'Contexts List Loading Failed': emptyProps(),
  },
});
