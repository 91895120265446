import { inject, Injectable } from '@angular/core';

import { map, mergeMap } from 'rxjs';

import { Actions, createEffect, ofType } from '@ngrx/effects';

import { AUTH_CONTEXT_DATA_ADAPTER } from './auth-context-data-adapter.token';
import { authContextDataAdapterActions } from './actions/auth-context-data-adapter.actions';
import { authContextFeatureActions } from './actions/auth-context-feature.actions';

@Injectable()
export class AuthContextEffects {
  private actions$ = inject(Actions);
  private dataAdapter = inject(AUTH_CONTEXT_DATA_ADAPTER);

  loadContextsList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authContextFeatureActions.loadContextsList),
      mergeMap(() =>
        this.dataAdapter
          .fetchAuthContextsList()
          .pipe(map((contextsList) => authContextDataAdapterActions.contextsListLoadedSuccessfully({ contextsList }))),
      ),
    ),
  );
}
