import { Component } from '@angular/core';
import { ChrComponentsModule } from '@ciphr-design-system/angular';

import { TranslocoDirective } from '@jsverse/transloco';

@Component({
  selector: 'ciphr-page-not-allowed',
  standalone: true,
  imports: [TranslocoDirective, ChrComponentsModule],
  templateUrl: './page-not-allowed.component.html',
  styleUrls: ['./page-not-allowed.component.scss'],
})
export class PageNotAllowedComponent {}
