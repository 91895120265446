import { Injectable, signal } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class AppPermissionsService {
  private permissions = signal<Set<string> | null>(null);

  hasPermission(permission: string): boolean {
    return !!this.permissions()?.has(permission);
  }

  setPermissions(permissions: Array<string>): void {
    this.permissions.set(new Set(permissions));
  }
}
