import { createFeature, createReducer, on } from '@ngrx/store';
import { immerOn } from 'ngrx-immer/store';

import { NavigationItem } from '@ciphr/core/app-navigation/models';

import { appNavigationDataAdapterActions } from './actions/app-navigation-data-adapter.actions';
import { appNavigationFeatureActions } from './actions/app-navigation-feature.actions';

export interface AppNavigationState {
  navigationHidden: boolean;
  navigationMenu: NavigationItem[];
  navigationOpened: boolean;
  navigationSearch: {
    loading: boolean;
    results: NavigationItem[];
    searchValue?: string;
  };
}

export const prepareAppNavigationInitialState = (): AppNavigationState => ({
  navigationHidden: true,
  navigationMenu: [],
  navigationOpened: false,
  navigationSearch: {
    loading: false,
    results: [],
  },
});

export const appNavigationFeature = createFeature({
  name: 'AppNavigation',
  reducer: createReducer(
    prepareAppNavigationInitialState(),
    on(appNavigationDataAdapterActions.navigationMenuLoadedSuccessfully, (state, { navigationMenu }) => ({
      ...state,
      navigationMenu: navigationMenu,
    })),
    on(appNavigationDataAdapterActions.navigationLinksSearchedSuccessfully, (state, { results }) => ({
      ...state,
      navigationSearch: {
        ...state.navigationSearch,
        loading: false,
        results,
      },
    })),
    on(appNavigationDataAdapterActions.navigationLinksSearchingFailed, (state) => ({
      ...state,
      navigationSearch: {
        ...state.navigationSearch,
        loading: false,
      },
    })),
    on(appNavigationFeatureActions.resetNavigationSearch, (state) => ({
      ...state,
      navigationSearch: {
        loading: false,
        results: [],
      },
    })),
    on(appNavigationFeatureActions.searchNavigationLinks, (state, { searchValue }) => ({
      ...state,
      navigationSearch: {
        ...state.navigationSearch,
        loading: true,
        searchValue,
      },
    })),
    on(appNavigationFeatureActions.setNavigationOpened, (state, { navigationOpened }) => ({
      ...state,
      navigationOpened,
    })),
    immerOn(appNavigationFeatureActions.toggleNavigationVisibility, (state) => {
      state.navigationHidden = !state.navigationHidden;
    }),
  ),
});
