import { Pipe, PipeTransform } from '@angular/core';

import { AuthContext } from '@ciphr/core/auth-context/models';

@Pipe({ name: 'authContextLabel', standalone: true })
export class AuthContextLabelPipe implements PipeTransform {
  transform(context: AuthContext): string {
    return `${context.adminUserLoginName ? 'Manager Account' : 'Own Record'} (${context.companyName})`;
  }
}
