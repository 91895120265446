import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { NavigationItem } from '@ciphr/core/app-navigation/models';

export const appNavigationDataAdapterActions = createActionGroup({
  source: 'App Navigation Data Adapter',
  events: {
    'Navigation Menu Loaded Successfully': props<{ navigationMenu: NavigationItem[] }>(),
    'Navigation Links Searched Successfully': props<{ results: NavigationItem[] }>(),
    'Navigation Links Searching Failed': emptyProps(),
  },
});
